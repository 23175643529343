<script setup lang="ts">
import { onMounted } from 'vue'

defineLayout({
  label: 'Hyatt Zilara Layout',
})

const isVoixTemplatizer = inject<boolean>('isVoixTemplatizer', false)
const { getCommonLayoutData, playaLayoutMounted, setupHeader } = usePlayaLayout()

const links: Array<object> = [
  { rel: 'icon', href: '/imgs/hyatt-ziva/favicons/icon-192.png', type: 'image/png' },
  { rel: 'icon', href: '/imgs/hyatt-ziva/favicons/icon.svg', type: 'image/svg+xml' },
  { rel: 'apple-touch-icon', href: '/imgs/hyatt-ziva/favicons/apple-touch-icon.png', type: 'image/png' },
  { rel: 'manifest', href: '/imgs/hyatt-ziva/favicons/manifest.json', type: 'application/json' },
]

if (!isVoixTemplatizer)
  setupHeader('f1c748e5-8ed2-46df-aee5-1ffc70f24022', links)

const {
  resorts,
  brands,
  currentResort,
  footerCompanyMenu,
  footerResourcesMenu,
  footerTermsMenu,
} = await getCommonLayoutData(isVoixTemplatizer)

onMounted(() => {
  if (!isVoixTemplatizer)
    return
  playaLayoutMounted(currentResort)
})
provide('accentColor', { value: '#d23476' })
provide('currentResort', currentResort.value.data)
provide('resorts', resorts.value.data)
provide('brands', brands.value.data)
</script>

<template>
  <div>
    <div
      class="text-grey-darker bg-abs-white sanctuary-theme"
    >
      <div id="app">
        <div id="portal-destination" transition="fade-transition" />
        <div id="portal-mobile-menu" role="navigation" />

        <div id="playa-content">
          <NavigationHeader
            v-if="!isVoixTemplatizer"
            :config="{
              stacked: false,
              logoPosition: 'left', // left or center
              menuPosition: 'right', // right or center
              backgroundClass: 'bg-white',
              bookNowBackground: 'bg-[#d23476]',
              bookNowText: 'text-white',
              languageSelectorBackground: 'bg-[#d23476]',
              languageSelectorText: 'text-white',
              paper: false,
              hyattAnimationPink: true,
              relativeLogo: true,
              darkTopBar: true,
              logoWidth: 200,
            }"
          />
          <slot />

          <NavigationFooter
            v-if="!isVoixTemplatizer"
            :footer-company-menu="footerCompanyMenu?.data"
            :footer-resources-menu="footerResourcesMenu?.data"
            :footer-terms-menu="footerTermsMenu?.data"
            :config="{
              crossPromotion: false,
              signUpForm: true,
              socialMenu: true,
            }"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<style lang="scss">
  @import '~/assets/css/hyatt-ziva.scss';
</style>
